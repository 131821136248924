<template>
  <nav-head />
  <router-view v-slot="{ Component }">
    <keep-alive>
      <component :is="Component" />
    </keep-alive>
  </router-view>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import NavHead from "./components/NavHead.vue";

export default defineComponent({
  components: { NavHead },
  name: "App",
});
</script>

<style lang="scss">
$bgColor: #484848;
@import "./style.scss";
#app {
  background: #000;
  min-height: 100vh;
  color: #fff;
  max-width: 800px;
  margin: auto;
  font-size: 14px;
}
.subtitle {
    background-color: $bgColor;
}
</style>

