import { createRouter, createWebHistory } from "vue-router"

const routes = [
    {
        path: "/",
        name: "index",
        component: () => import(/* webpackChunkName: "index" */ '@/views/Index/index.vue')
    },
    {
        path: "/choose",
        name: "choose",
        component: () => import('@/views/choose/index.vue')
    },
    {
        path: "/result/:type",
        name: "result",
        component: () => import('@/views/result/index.vue')
    }
]



export default createRouter({
    history: createWebHistory(),
    routes
})
