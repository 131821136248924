<template>
  <div class="nav_head">
    <img src="@/assets/logo.png" />
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "nav-head",
});
</script>

<style scoped>
img {
  max-width: 120px;
  width: 30%;
}
.nav_head {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50px;
  padding: 5px 10px;
}
</style>